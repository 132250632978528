import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { uid } from "react-uid";
import { useStaticQuery, graphql } from "gatsby";
import { up } from "styled-breakpoints";
import * as UI from "components/ui";
import { useTranslatedHomePagePath, useFormatMessage } from "context/Intl";
import { useAppStore } from "context/App";
import useMediaQuery from "hooks/useMediaQuery";
import useWindowScroll from "hooks/useWindowScroll";
import Facebook from "assets/facebook.svg";
import Youtube from "assets/youtube.svg";
import Twitter from "assets/twitter.svg";
import Linkedin from "assets/linkedin.svg";
import Instagram from "assets/ig.svg";
import CalendarIcon from "assets/calendar.svg";

interface IProps {}

type ILink = {
  title?: string;
  icon?: () => React.ReactNode;
  link?: string;
  links?: ILink[];
  id?: string;
};

interface IWrapperProps {
  condition: boolean;
  wrapper1: (c: any) => JSX.Element | null;
  wrapper2: (c: any) => JSX.Element | null;
  children: any;
}

const Logo = styled.div`
  height: 100%;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  align-self: flex-start;

  ${up("lg")} {
    margin-bottom: ${({ theme }) => theme.spacing(3)};
  }
`;

const Container = styled.footer`
  margin-top: ${({ theme }) => theme.spacing(18)};
  border-top: 1px solid ${({ theme }) => theme.palette.lightGrey};
`;

const TopBar = styled(UI.Wrap)`
  ul {
    > li:not(:last-child) {
      margin-bottom: ${({ theme }) => theme.spacing(2)};
    }
  }

  a:hover {
    text-decoration: underline;
  }

  > ${Logo} {
    margin-top: ${({ theme }) => theme.spacing(3)};
    border-bottom: 1px solid ${({ theme }) => theme.palette.lightGrey};
    padding-bottom: ${({ theme }) => theme.spacing(3)};

    ${up("lg")} {
      display: none;
    }
  }
`;

const LinkBar = styled.nav`
  display: flex;
  flex-flow: wrap;
  flex-direction: column;

  ${up("lg")} {
    flex-direction: row;
    padding-top: ${({ theme }) => theme.spacing(8)};
  }

  ${up("xl")} {
    justify-content: space-between;
  }
`;

const Column = styled.div`
  padding-right: 3.5vw;
  margin-bottom: ${({ theme }) => theme.spacing(8)};

  &:last-child {
    padding-right: 0;
  }

  ${up("xl")} {
    width: 20%;
  }
`;

const LinkHeader = styled.h2`
  width: auto;
  margin-bottom: ${({ theme }) => theme.spacing(3)};
  padding-bottom: 0;
  padding-top: 0;
  border-top: none;
  font-weight: bold;
`;

const LinkList = styled.ul<{ bold?: boolean }>`
  display: block;
  font-weight: ${({ bold }) => (bold ? "bold" : "normal")};

  ${up("lg")} {
    display: block;
  }
`;

const BottomBar = styled.div`
  border-top: 1px solid ${({ theme }) => theme.palette["lightGrey"]};
  padding-top: ${({ theme }) => theme.spacing(4)};
  padding-bottom: ${({ theme }) => theme.spacing(8)};

  > div {
    justify-content: space-between;
    align-items: center;
    display: flex;
  }

  ${Logo} {
    display: none;

    ${up("lg")} {
      display: block;
    }
  }
`;

const Links = styled.ul`
  margin-bottom: ${({ theme }) => theme.spacing(1.5)};
  flex-flow: row wrap;
  display: none;

  ${up("lg")} {
    display: flex;
  }

  > li a {
    display: block;
    margin-right: 6px;

    &:after {
      content: " · ";
      margin-left: 3px;
    }
  }

  > li:last-child a {
    margin-right: 0;

    &:after {
      display: none;
    }
  }
`;

const TextWrapper = styled.div`
  width: 100%;
`;

const Copyright = styled.div`
  text-align: center;
  width: 60%;
  margin: auto;

  ${up("lg")} {
    width: 100%;
    margin: 0;
    text-align: left;
  }
`;

const SocialBar = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: ${({ theme }) => theme.spacing(5)};
  width: 80%;
  margin: auto;
  justify-content: space-evenly;

  ${up("lg")} {
    justify-content: flex-start;
    margin: 0;
    padding-top: ${({ theme }) => theme.spacing(6)};
    border-top: 1px solid ${({ theme }) => theme.palette["lightGrey"]};
    margin-top: ${({ theme }) => theme.spacing(4)};
    width: 100%;

    a {
      margin-right: ${({ theme }) => theme.spacing(3)};
      transition: opacity 0.15s cubic-bezier(0.65, 0.05, 0.36, 1);

      &:hover {
        opacity: 0.7;
      }
    }
  }
`;

const ContactButton = styled.div<{ isVisible: boolean }>`
  position: fixed;
  bottom: 20px;
  right: 100px;
  pointer-events: ${({ isVisible }) => (isVisible ? "auto" : "none")};
  opacity: ${({ isVisible }) => (isVisible ? "1" : "0")};
  transform: ${({ isVisible }) => (isVisible ? "translateY(0)" : "translateY(100px)")};
  transition: all 0.3s cubic-bezier(0.65, 0.05, 0.36, 1);
  will-change: transform opacity;
  backface-visibility: hidden;
  z-index: 1;
`;

const socialIcons = {
  Youtube: () => <Youtube aria-label="Youtube" />,
  Linkedin: () => <Linkedin aria-label="Linkedin" />,
  Instagram: () => <Instagram aria-label="Instagram" />,
  Twitter: () => <Twitter aria-label="Twitter" />,
  Facebook: () => <Facebook aria-label="Facebook" />,
};

const Wrapper = ({ condition, wrapper1, wrapper2, children }: IWrapperProps) => {
  return condition ? wrapper1(children) : wrapper2(children);
};

const SiteFooter: React.FC<IProps> = () => {
  const footerData = useStaticQuery<Gatsby.SiteFooterQuery>(SiteFooterQuery);
  const { site: siteData, logo } = footerData;
  const isLg = useMediaQuery("lg");
  const formatMessage = useFormatMessage();
  const translatedHomePagePath = useTranslatedHomePagePath();
  const { overlayIsVisible, setOverlayIsVisible } = useAppStore();
  const toggleOverlay = () => setOverlayIsVisible(!overlayIsVisible);
  const [contactButtonIsVisible, setContactButtonIsVisible] = useState(false);
  const { scrollPosition, scrollDirection } = useWindowScroll();

  const [menuLinks, setMenuLinks] = useState<ILink[]>([]);
  const [legalLinks, setLegalLinks] = useState<ILink[]>([]);
  const [socialLinks, setSocialLinks] = useState<ILink[]>([]);

  useEffect(() => {
    if (scrollDirection === 1 && scrollPosition?.y && scrollPosition.y > 120) {
      setContactButtonIsVisible(true);
    } else {
      setContactButtonIsVisible(false);
    }
  }, [scrollPosition, scrollDirection]);

  useEffect(() => {
    const links = footerData.menuLinks.nodes;
    const menuLinks = links
      .filter((l) => !l.parent)
      .map((link) => ({
        title: link.title,
        link: link.link?.uri,
        links: [] as ILink[],
        id: link.id,
      }));

    links
      .filter((l) => l.parent)
      .forEach((link) => {
        const parentId = link.parent?.replace("menu_link_content:", "");
        const parent = menuLinks.find((l) => l.id === parentId);

        parent?.links.push({
          title: link.title,
          link: link.link?.uri,
        });
      });

    setMenuLinks(menuLinks);
  }, []);

  useEffect(() => {
    const legalLinks = footerData.legalLinks.nodes.map((link) => ({
      title: link.title,
      link: link.link?.uri,
    }));
    setLegalLinks(legalLinks);
  }, []);

  useEffect(() => {
    const socialLinks = footerData.socialLinks.nodes.map((link) => ({
      title: link.title,
      link: link.link?.uri,
      icon: link.title && (socialIcons as any)[link.title],
    }));
    setSocialLinks(socialLinks);
  }, []);

  if (isLg === null) {
    return null;
  }

  return (
    <Container>
      <ContactButton isVisible={contactButtonIsVisible}>
        {isLg ? (
          <UI.Button onClick={toggleOverlay} variant="secondary">
            <UI.Icon label={formatMessage("Request an Appointment")}>
              <CalendarIcon />
            </UI.Icon>
          </UI.Button>
        ) : isLg !== null ? (
          <UI.Button onClick={toggleOverlay} variant="secondary">
            {formatMessage("Request an Appointment")}
          </UI.Button>
        ) : null}
      </ContactButton>
      <TopBar>
        <Logo>
          <UI.Link to={translatedHomePagePath}>
            <img src={logo?.publicURL} alt={siteData?.siteMetadata?.title} width={200} height={18} />
          </UI.Link>
        </Logo>
        <LinkBar role="navigation" aria-label="Content Info">
          {menuLinks.map((item, i) => {
            return (
              <Wrapper
                key={uid("wrapper" + i)}
                condition={isLg}
                wrapper1={(children: any) => <Column>{children}</Column>}
                wrapper2={(children: any) =>
                  item.title ? <UI.Expandable title={item.title}>{children}</UI.Expandable> : null
                }
              >
                {isLg && (
                  <LinkHeader>
                    <UI.Text color="greyNightmare" typography={"t16Fakt"}>
                      <UI.Link to={item.link!}>{item.title}</UI.Link>
                    </UI.Text>
                  </LinkHeader>
                )}
                <LinkList bold={item.title === "About Us"}>
                  {item.links &&
                    item.links.map(
                      (link) =>
                        link.link && (
                          <li key={uid(link + (link.title || ""))}>
                            <UI.Link to={link.link}>
                              <UI.Text typography={"t16Fakt"} color={"greyNightmare"}>
                                {link.title}
                              </UI.Text>
                            </UI.Link>
                          </li>
                        )
                    )}
                </LinkList>
                {isLg && item.title === "About Us" && (
                  <SocialBar>
                    {socialLinks.map((link, i) => {
                      return (
                        link.link && (
                          <UI.Link to={link.link} key={uid("social" + link.link + i)} title={link.title}>
                            <UI.Icon>{link.icon && link.icon()}</UI.Icon>
                          </UI.Link>
                        )
                      );
                    })}
                  </SocialBar>
                )}
              </Wrapper>
            );
          })}
        </LinkBar>
      </TopBar>
      <BottomBar>
        <UI.Wrap>
          <TextWrapper>
            <nav role="navigation" aria-label="Privacy Links">
              <Links>
                {legalLinks.map((link, i) => {
                  return (
                    link.link && (
                      <li key={uid("legal_" + i)}>
                        <UI.Link to={link.link}>
                          <UI.Text typography={"t12Fakt"} color={"buttonBlue"}>
                            {link.title}
                          </UI.Text>
                        </UI.Link>
                      </li>
                    )
                  );
                })}
              </Links>
            </nav>
            {!isLg && (
              <SocialBar>
                {socialLinks.map((link, i) => {
                  return (
                    <UI.Link to={link.link!} key={uid("socialMobile" + link.link + i)}>
                      <UI.Icon>{link.icon && link.icon()}</UI.Icon>
                    </UI.Link>
                  );
                })}
              </SocialBar>
            )}
            <Copyright>
              <UI.Text typography={"t12Fakt"} color={"greyNightmare"}>
                &copy; {new Date().getFullYear()} Montefiore Medical Center · 111 East 210th Street, Bronx, NY 10467 ·
                718-920-4321
              </UI.Text>
            </Copyright>
          </TextWrapper>
          <Logo>
            <UI.Link to={translatedHomePagePath}>
              <img src={logo?.publicURL} alt={siteData?.siteMetadata?.title} width={200} height={18} />
            </UI.Link>
          </Logo>
        </UI.Wrap>
      </BottomBar>
    </Container>
  );
};

const SiteFooterQuery = graphql`
  query SiteFooter {
    site {
      siteMetadata {
        title
      }
    }
    logo: file(name: { eq: "logo" }) {
      publicURL
    }
    menuLinks: allMenuLinkContentFooter {
      nodes {
        id: drupal_id
        link {
          uri
          title
        }
        title
        parent: drupal_parent_menu_item
      }
    }
    legalLinks: allMenuLinkContentLegal {
      nodes {
        link {
          uri
        }
        title
      }
    }
    socialLinks: allMenuLinkContentSocial {
      nodes {
        link {
          uri
        }
        title
      }
    }
  }
`;

export default SiteFooter;
